import React from 'react'
import Layout from '../components/Layout'
// import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ReactPlayer from 'react-player'
import startupHeader from '../../static/img/startup-header.jpg'
import startupFooter from '../../static/img/startup-footer.png'
import '../fonts/layout.css'

const twentytwentyshowcase = ({ data, location }) => {
    const jsonData = data.allArticlesJson.edges[0].node.articles
    return (
        <Layout data={data} jsonData={jsonData} location={location}>
            <>
                <section className="hero is-medium">
                    <div className="hero-body">
                        <div
                            className="container"
                            style={{ maxWidth: '800px' }}
                        >
                            <div
                                style={{
                                    maxWidth: `800px`,
                                    marginBottom: `1.45rem`,
                                    // marginTop: `1.45rem`,
                                    marginLeft: `auto`,
                                    marginRight: `auto`,
                                }}
                            >
                                <img src={startupHeader} />
                            </div>
                            <h1
                                className="header-would-small"
                                style={{
                                    textAlign: `center`,
                                    marginLeft: `auto`,
                                    marginRight: `auto`,
                                    fontFamily: `Lato`,
                                    fontSize: `55px`,
                                    // lineHeight: `65px`
                                    fontWeight: `700`,
                                    marginBottom: `20px`,
                                }}
                            >
                                Compete for a Seat in Silicon Valley.
                            </h1>

                            <p
                                style={{
                                    textAlign: `center`,
                                    marginLeft: `auto`,
                                    marginRight: `auto`,
                                    marginBottom: `60px`,
                                    fontFamily: `Lato`,
                                    fontSize: `22px`,
                                    color: `#666`,
                                }}
                            >
                                Startup World Cup comes to Georgia, but this
                                time online, hosting a competition of startups
                                from 50 countries across Europe and Asia. The
                                winner of the competition will go on to compete
                                in the Global Finale for $1,000,000 investment
                                prize and international visibility.
                            </p>

                            <div>
                                <article>
                                    <span
                                        style={{
                                            fontFamily: `Lato`,
                                            fontSize: `22px`,
                                            color: `black`,
                                        }}
                                    >
                                        1. <b>Competition</b> (Apply by{' '}
                                        <span>December 7th to compete</span>
                                        ) <br />
                                        Register{' '}
                                        <a
                                            style={{
                                                color: `#0600ff`,
                                                textDecoration: `underline`,
                                                fontWeight: `700`,
                                                fontFamily: `Lato`,
                                            }}
                                            href="https://www.startupworldcup.io/europe-asia-app"
                                        >
                                            HERE
                                        </a>
                                    </span>
                                </article>
                                <article>
                                    <span
                                        style={{
                                            fontFamily: `Lato`,
                                            fontSize: `22px`,
                                            color: `black`,
                                        }}
                                    >
                                        2. <b>Startup Showcase</b> (Apply by{' '}
                                        <span>December 8th for showcase) </span>{' '}
                                        <br />
                                        Tickets{' '}
                                        <a
                                            style={{
                                                color: `#0600ff`,
                                                textDecoration: `underline`,
                                                fontWeight: `700`,
                                                fontFamily: `Lato`,
                                            }}
                                            href="https://www.startupgrind.com/events/details/startup-grind-tbilisi-presents-startup-grind-europe-asia-connect-3//#/code-invitedstartup"
                                        >
                                            HERE
                                        </a>
                                    </span>
                                </article>
                                <p
                                    style={{
                                        fontFamily: `Lato`,
                                        fontSize: `22px`,
                                        color: `black`,
                                        marginTop: `50px`,
                                        marginBottom: `70px`,
                                    }}
                                >
                                    Ticket purchase is not required to compete
                                    but it provides expo space, training from
                                    Silicon Valley mentors, special networking
                                    opportunities and visibility. If you just
                                    enter the competition you don't get the
                                    conference showcase package or
                                    training/coaching
                                </p>

                                <div>
                                    <h2
                                        className="header-would-small"
                                        style={{
                                            textAlign: `center`,
                                            // marginLeft: `auto`,
                                            // marginRight: `auto`,
                                            fontFamily: `Lato`,
                                            fontSize: `48px`,
                                            color: `#494a52                                        `,
                                            // lineHeight: `65px`
                                            marginBottom: `70px`,
                                        }}
                                    >
                                        Startup Showcase
                                    </h2>

                                    <ul
                                        style={{
                                            fontSize: `1.1em`,
                                            listStyleType: `disc`,
                                            color: `#666`,
                                        }}
                                    >
                                        <li
                                            style={{
                                                fontSize: `30px`,
                                                fontFamily: `Lato`,
                                                marginLeft: `40px`,
                                                fontWeight: `600`,
                                            }}
                                        >
                                            Training from Silicon Valley Experts
                                        </li>
                                        <li
                                            style={{
                                                fontSize: `30px`,
                                                fontFamily: `Lato`,
                                                marginLeft: `40px`,
                                                fontWeight: `600`,
                                            }}
                                        >
                                            Visibility internationally at
                                            Startup Grind Europe-Asia Connect
                                        </li>
                                        <li
                                            style={{
                                                fontSize: `30px`,
                                                fontFamily: `Lato`,
                                                marginLeft: `40px`,
                                                fontWeight: `600`,
                                            }}
                                        >
                                            Top 10 finalists get Individual
                                            Coaching
                                        </li>
                                        <li
                                            style={{
                                                fontSize: `30px`,
                                                fontFamily: `Lato`,
                                                marginLeft: `40px`,
                                                fontWeight: `600`,
                                            }}
                                        >
                                            Exhibit at Startup Showcase
                                        </li>
                                    </ul>

                                    <p
                                        style={{
                                            fontFamily: `Lato`,
                                            fontSize: `24px`,
                                            color: `#494a52`,
                                            fontWeight: `700`,
                                            marginBottom: `30px`,
                                            marginTop: `50px`,
                                        }}
                                    >
                                        Regardless of whether you win or lose
                                        the competition you can supercharge your
                                        participation in Startup Grind
                                        Europe-Asia Connect in front of
                                        investors, business leaders, potential
                                        advisors and technical talent.
                                    </p>

                                    <p
                                        style={{
                                            fontFamily: `Lato`,
                                            fontWeight: `700`,
                                            fontSize: `22px`,
                                            color: `#666`,
                                        }}
                                    >
                                        For just $199 put your startup Front and
                                        Center at the most exciting online event
                                        ever hosted by Startup Grind!
                                    </p>
                                </div>
                                <div
                                    style={{
                                        display: `flex`,
                                        justifyContent: `center`,
                                    }}
                                >
                                    <a
                                        className="green-button"
                                        style={{
                                            color: `white`,
                                            textTransform: `uppercase`,
                                            fontSize: `22px`,
                                            fontFamily: `Lato`,
                                            backgroundColor: `#69A849`,
                                            paddingTop: `13px`,
                                            paddingBottom: `13px`,
                                            paddingLeft: `30px`,
                                            paddingRight: `30px`,
                                            border: `none`,
                                            borderRadius: `6px`,
                                            marginTop: `30px`,
                                            marginBottom: `40px`,
                                        }}
                                        href="https://www.startupgrind.com/events/details/startup-grind-tbilisi-presents-startup-grind-europe-asia-connect-3//#/code-invitedstartup"
                                    >
                                        showcase your startup!
                                    </a>
                                </div>
                            </div>
                            <div>
                                <h2
                                    style={{
                                        fontFamily: `Lato`,
                                        fontWeight: `700`,
                                        color: `#494a52`,
                                        fontSize: `28px`,
                                    }}
                                >
                                    What if I don’t make it to semi-finals?
                                </h2>
                                <p
                                    style={{
                                        fontSize: `22px`,
                                        fontWeight: `700`,
                                        fontFamily: `Lato`,
                                        color: `#666`,
                                        marginTop: `25px`,
                                    }}
                                >
                                    That's fine, you still get great benefits
                                </p>
                                <p
                                    style={{
                                        fontSize: `22px`,
                                        fontWeight: `400`,
                                        fontFamily: `Lato`,
                                        color: `#666`,
                                        marginBottom: `25px`,
                                    }}
                                >
                                    All Startup Showcase participants get a
                                    benefits package which fosters visibility
                                    and networking and is independent of
                                    performance in the competition:
                                </p>
                            </div>

                            <div
                                style={{
                                    fontSize: `22px`,
                                    fontFamily: `Lato`,
                                }}
                            >
                                <ul
                                    style={{
                                        listStyleType: `circle`,
                                        paddingLeft: `40px`,
                                    }}
                                >
                                    <li
                                        style={{
                                            fontSize: `1em`,
                                            color: `#666`,
                                        }}
                                    >
                                        <b>
                                            Training videos from US Market
                                            Access Center to help you perfect
                                            your pitch.
                                        </b>
                                    </li>
                                    <li
                                        style={{
                                            fontSize: `1em`,
                                            color: `#666`,
                                        }}
                                    >
                                        <b>Startup Stage Pitch</b>
                                        <ul style={{ listStyleType: `square` }}>
                                            <li style={{ marginLeft: `50px` }}>
                                                We will have slots for all
                                                startups to deliver a pitch on
                                                the Startup Stage. It is a time
                                                for you to invite people to hear
                                                your pitch as part of a big
                                                event!
                                            </li>
                                        </ul>
                                    </li>
                                    <li
                                        style={{
                                            fontSize: `1em`,
                                            color: `#666`,
                                        }}
                                    >
                                        <b>Investor Visibility:</b>
                                        <ul style={{ listStyleType: `square` }}>
                                            <li style={{ marginLeft: `50px` }}>
                                                Deck, website, and contact
                                                information will be shared with
                                                all participating investors
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div
                                style={{
                                    fontSize: `22px`,
                                    fontFamily: `Lato`,
                                }}
                            >
                                <ul
                                    style={{
                                        marginTop: `35px`,
                                        marginBottom: `35px`,
                                        listStyleType: `disc`,
                                    }}
                                >
                                    <li
                                        style={{
                                            fontSize: `1em`,
                                            color: `#666`,
                                        }}
                                    >
                                        <b>Social Media Promotion</b>
                                        <ul
                                            style={{
                                                marginLeft: `30px`,
                                                listStyleType: `circle`,
                                            }}
                                        >
                                            <li>
                                                All Showcase participants will
                                                be part of at least one
                                                “shout-out” on Linkedin and
                                                Facebook drawing visibility
                                            </li>
                                        </ul>
                                    </li>
                                    <li
                                        style={{
                                            fontSize: `1em`,
                                            color: `#666`,
                                        }}
                                    >
                                        <b>Startup Showcase</b>
                                        <ul
                                            style={{
                                                marginLeft: `30px`,
                                                listStyleType: `circle`,
                                            }}
                                        >
                                            <li>
                                                Startups can participate in Expo
                                                during the conference to network
                                                and showcase their startups live
                                                or with video/web content
                                            </li>
                                        </ul>
                                    </li>
                                    <li
                                        style={{
                                            fontSize: `1em`,
                                            color: `#666`,
                                        }}
                                    >
                                        <b> Special access </b> to private
                                        venues in VirBELA during conference
                                        networking times, SWC reception and
                                        closing party. These venues will include
                                        investors and top 2% participants
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2
                                    style={{
                                        fontFamily: `Lato`,
                                        fontWeight: `700`,
                                        color: `#494a52`,
                                        fontSize: `28px`,
                                    }}
                                >
                                    What if I DO make it to semi-finals?
                                </h2>
                                <p
                                    style={{
                                        fontSize: `22px`,
                                        fontWeight: `400`,
                                        fontFamily: `Lato`,
                                        color: `#666`,
                                        marginTop: `25px`,
                                    }}
                                >
                                    The Startup Showcase program means you get
                                    extra benefits at Startup Grind Europe-Asia
                                    Connect.
                                </p>
                            </div>
                            <div>
                                <div>
                                    <p
                                        style={{
                                            marginTop: `35px`,
                                            marginBottom: `20px`,
                                            fontFamily: `Lato`,
                                            fontWeight: `400`,
                                            fontSize: `22px`,
                                        }}
                                    >
                                        <b>If you're top 10</b> you get:
                                    </p>
                                    <ul
                                        style={{
                                            fontSize: `22px`,
                                            listStyleType: `disc`,
                                            fontFamily: `Lato`,
                                            fontWeight: `400`,
                                            color: `#666`,
                                            marginLeft: `40px`,
                                        }}
                                    >
                                        <li>
                                            An individual coaching and feedback
                                            session for your pitch from Silicon
                                            Valley experts in preparation for
                                            the final competition
                                        </li>
                                        <li>
                                            Special visibility before investors
                                            and indication of your top finalist
                                            status
                                        </li>
                                        <li>
                                            Exhibit in the Virbela and Bevy
                                            Online Expos of the event in Top 10
                                            section
                                        </li>
                                        <li>
                                            Social media post and presence on
                                            website with your logo, company
                                            name, link
                                        </li>
                                        <li>
                                            Listing on web as a top 10 regional
                                            finalist
                                        </li>
                                        <li>
                                            Automatic acceptance into Startup
                                            Grind Startup Program
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <p
                                        style={{
                                            marginTop: `35px`,
                                            marginBottom: `20px`,
                                            fontFamily: `Lato`,
                                            fontWeight: `400`,
                                            fontSize: `22px`,
                                        }}
                                    >
                                        If you win the competition?
                                    </p>
                                    <ul
                                        style={{
                                            fontSize: `22px`,
                                            listStyleType: `disc`,
                                            fontFamily: `Lato`,
                                            fontWeight: `400`,
                                            color: `#666`,
                                            marginLeft: `40px`,
                                        }}
                                    >
                                        <li>
                                            You get additional special social
                                            media campaign with 250,000 reach
                                            and association with the Startup
                                            Grind Europe-Asia Connect brand
                                        </li>
                                        <li>
                                            Sponsored travel package ($2500) to
                                            attend Startup World Cup competition
                                            in Silicon Valley
                                        </li>
                                        <li>
                                            All existing benefits of competing
                                            for $1 million investment prize,
                                            plus inclusion in "side events"
                                            around the Startup World Cup Grand
                                            Finale
                                        </li>
                                        <li>
                                            Press release sent to your local
                                            media
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginTop: `40px`,
                                    marginBottom: `40px`,
                                }}
                            >
                                <ReactPlayer
                                    className="react-player"
                                    width="100%"
                                    height="100%"
                                    url="https://www.youtube.com/watch?v=HMXb2L1m1i4"
                                    height={400}
                                />
                            </div>

                            <div
                                style={{
                                    maxWidth: `800px`,
                                    marginBottom: `1.45rem`,
                                    marginTop: `1.45rem`,
                                    marginLeft: `auto`,
                                    marginRight: `auto`,
                                }}
                            >
                                <img src={startupFooter} />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Layout>
    )
}

twentytwentyshowcase.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
}

export default twentytwentyshowcase

export const pageQuery = graphql`
    query twentytwentyshowcaseQuery {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark {
            html
            frontmatter {
                id
                title
            }
            fields {
                slug
            }
        }
    }
`
